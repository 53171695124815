import React from 'react';
import styled from 'styled-components';
import { API } from '../../../settings';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Bookmark24Regular, Bookmark24Filled } from '@fluentui/react-icons';
import { track, SAVE_SUBCATEGORY, UNSAVE_SUBCATEGORY } from '../../../utils/mixpanel';

const Button = styled.button`
  height: 50px;
  text-align: center;
  justify-content: center;
  margin-top: auto;

  &.default {
    color: #b6b6b6;
  }
`;

class SaveSubcategoryButton extends React.Component {
  state = {
    isSaved: false,
    loading: true,
  };

  componentDidMount() {
    const { subcategoryId } = this.props;

    API.get(`/subcategories/saved/${subcategoryId}`)
      .then((response) => {
        // TODO: component is rendered multiple times... look at useEffect in parent component(?)
        console.log('THE RESPONSE', response.data.isSaved);
        this.setState({ isSaved: response.data.isSaved, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  }

  onInterest = () => {
    const { subcategoryId } = this.props;
    const { isSaved } = this.state;

    track(isSaved ? UNSAVE_SUBCATEGORY : SAVE_SUBCATEGORY, { name: this.props?.name, key: this.props?.cluster?.key})

    API({
      url: `/subcategories/saved/${subcategoryId}`,
      method: isSaved ? 'DELETE' : 'POST',
    }).then(() => {
      
      this.setState({ isSaved: !isSaved });

      toast.success(`${isSaved ? 'Notifications disabled!' : 'Notifications Enabled!'}`, {
        style: {
          background: isSaved ? '#ffc34e' : '#46d191',
        },
      });
    });
  };

  render() {
    const { isSaved, loading } = this.state;
    const { isSingleOpportunity } = this.props

    if (loading) {
      return (
        <Button disabled="disabled" className="ln-btn primary full-width">
          <Spinner size="sm" animation="border" />
        </Button>
      );
    };

    let text;

    if (isSingleOpportunity) {
      text = isSaved ? 'Disable similar opportunity notifications' : 'Notify me of similar opportunities';
    } else {
      text = isSaved ? 'Disable opportunity notifications' : 'Notify me of opportunities';
    }

    return (
      <>
        <Button 
        style={{ opacity: isSaved ? 0.5 : 1}}
        onClick={this.onInterest} 
        className="ln-btn primary full-width">
          {isSaved ? <Bookmark24Filled /> : <Bookmark24Regular />}
          {text}
        </Button>
      </>
    );
  }
}

export default SaveSubcategoryButton;
