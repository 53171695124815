import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import { API } from '../settings';
import { getJobs, setLoading } from '../redux/actions/counselor';
import ConfirmLn from './common/ConfirmLn';

dotenv.config();

const StudentsWrapper = styled.div`
  background: #ffffff;
  border-radius: 1.25rem;

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const StatusButtonWrapper = styled.button`
  font-weight: 800;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: gray;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const StatusButton = (props) => {

  console.log(props)

  return <StatusButtonWrapper {...props} />
}

const CounselorApproved = styled(StatusButton)`
  background-color: lightgreen;
  color: green;
`;

const EmployerApproved = styled(StatusButton)`
  background-color: orange;
  color: white;
  pointer-events: none;
`;

const Finished = styled(StatusButton)`
  background-color: lightgreen;
  color: green;
  pointer-events: none;
  opacity: 0.8;
`;

const Closed = styled(StatusButton)`
  background-color: gray;
  opacity: 0.5;
  pointer-events: none;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;




function StudentJobList(props) {


  const approveToOpportunity = async (studentId, opportunityId) => {
    if (props.loading.includes(studentId)) return;
    props.setLoading(studentId);
    await API.patch(`/counselors/approve/${opportunityId}`, { studentId });
    props.getJobs();
    props.setLoading(studentId);
  }

  const onClickButton = (row) => () => {
    if (props.positionsAvailable === 0) return;
    if (row.finished) return;
    if (row.approvedByEmployer) return;
    return approveToOpportunity(row.id, props.opportunityId);
  }

  const StatusBtn = ({row}) => {
    console.log(props.positionsAvailable, row)


    const p = { 
      onClick : onClickButton(row),
      disabled: row.disabled
    }

    if (row.finished) return <Finished {...p}>Finished Opportunity</Finished>
    if (row.approvedByEmployer) return <EmployerApproved {...p}>Approved by Employer</EmployerApproved>
    if (row.approvedByCounselor) return <CounselorApproved {...p}>Approved by Counselor</CounselorApproved>
    if (!props.isActive) return <Closed {...p}>Approve student</Closed>
    if (props.positionsAvailable == 0) return <Closed {...p}>Approve student</Closed>
    return <ConfirmLn
      renderBtn={<StatusButton>Approve student</StatusButton>}
      title={`This action will send student information to the employer. Confirm?`}
      onOk={onClickButton(row)}
      position={'left'}
      okText="Yes"
      noText="No, Cancel"
    />
  }

  const statusButton = (loading) => (cell, row) => (<StatusBtn row={row} loading={loading} />)

  const gradeFormatter = (cell, row) => {
    if (cell == 13) cell = '12+';
    return <span>{cell}th</span>;
  };

  const nameFormatter = (cell, row) => {
    return <Link to={`/counselor/students/${row.uuid}`}>{row.firstName} {row.lastName}</Link>;
  };

  let columns = [
    {
      text: 'Name',
      formatter: nameFormatter,
      classes: 'name',
      dataField: 'name'
    },
    {
      text: 'Grade',
      dataField: 'grade',
      formatter: gradeFormatter,
      classes: 'grade',
    },
    {
      text: 'Status',
      dataField: 'loading',
      formatter: statusButton(props.loading),
    },
  ]  

  const paginationConfig = {
    sizePerPage: 100,
    hideSizePerPage: true,
  };

  const studentData = props.students.map((data) => ({
    ...data,
    disabled: props.loading.includes(data.id)
  }));

  return (
    <Container className="p-0 h-100 ">
      <Row>
        <Col>
          <StudentsWrapper>
            <BootstrapTableWrapper>
              <BootstrapTable
                columns={columns}
                data={studentData}
                keyField="name"
                pagination={paginationFactory(paginationConfig)}
              />
            </BootstrapTableWrapper>
          </StudentsWrapper>
        </Col>
      </Row>
    </Container>
  );

}

const mapStateToProps = (state) => {
  return { loading: state.counselor.jobs.loadingStudent }
};

const mapDispatchToProps = { getJobs, setLoading }

export default connect(mapStateToProps, mapDispatchToProps )(StudentJobList);
