import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import StudentProfileAssessmentSection from './StudentProfileAssessmentSection';
import { API } from '../settings';
import { getStudentProfile } from '../redux/actions/student';
import TabBar from './student-activity/shared/tab-bar';
import { ActivityTab } from './student-activity/activity-tab';
import { clusterActivityData } from '../utils/cluster-by-date';
import addPhoto from '../images/add-photo.svg';
import Loader from './common/Loader';

const PageWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  min-height: -webkit-fill-available;
  padding: 25px;
  position: relative;
  width: 100%;
  padding-bottom: 80px;
`;


const ProfileHeader = styled.div`
  text-align: center;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const SettingsLink = styled(Link)`
  color: #797878;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;

  &:hover {
    color: #797878;
  }
`;

const ProfileName = styled.h4`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.span`
  margin-top: 8px;
  width: 100%;
  display: block;
`;

const ProfileGrade = styled.span`
  display: inline-block;
  margin-top: 8px;
  background: #ffffff;
  border-radius: 12px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.5rem 0.75rem;
`;

const StudentAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const Wrapper = styled.div`
  margin-left: -25px;
  margin-right: -25px;
`;

const StudentPhotoWrapper = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  position: relative;
  margin: auto;
  background: white;
`;

const ProfilePic = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 100px;
`;


const StudentPhoto = ({ user }) => {
  const [profilePic, setProfilePic] = useState(null)
  
  useEffect(() => {
    const getProfilePic = async () => {
      const result = await API.get(`/users/profile-pic/${user.id}`);
      setProfilePic(result.data);
    };

    getProfilePic();
  }, []);

  return <StudentPhotoWrapper>
      {profilePic ? 
      <ProfilePic src={profilePic} /> :
      <StudentAvatar
        className="profileAvatar"
        round={true}
        size={100}
        name={`${user.firstName} ${user.lastName}`}
        color="white"
      />}
    </StudentPhotoWrapper>
}

class StudentProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      profileDataLoaded: false,
      percentDone: 0,
      grade: null,
      retakeAssessment: false,
      tab: 'Activity',
      activity: [],
      activityLoading: true,
    };

    this.retakeAssessment = this.retakeAssessment.bind(this);
    this.loadActivity = this.loadActivity.bind(this);
  }

  async loadActivity() {
    this.setState({ activityLoading: true })
    const res = await API.get(`/activity/self`);
    const { activity } = res.data;
    this.setState({ 
      activity: clusterActivityData(activity),
      loading: false
    });
  }

  componentDidMount() {
    this.loadActivity();
    if (!this.props.grade) {
      this.props.getStudentProfile();
    }
    // const { user } = this.props;
    // API.get(`/users/${user.uuid}/student-profile-data`)
    //   .then((response) => {
    //     const { grade, percentDone } = response.data;
    //
    //     this.setState({ grade, percentDone, profileDataLoaded: true });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  retakeAssessment(uuid) {
    API.post(`/students/${uuid}/retake-assessment`)
      .then((response) => {
        this.setState({ retakeAssessment: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { user, grade, percentDone, retakeAssessment, loading } = this.props;

    const renderStudentProfileDetails = () => {
      const gradeText = grade > 12 ? '12+' : grade
      
      if (!loading) {
        return (
          <>
            
            <ProfileEmail>{user.email}</ProfileEmail>
            <ProfileGrade>{`${gradeText}th Grade`}</ProfileGrade>
            <TabBar 
                style={{ margin: 0, marginBottom: 30, marginTop: 30 }} 
                optionA='Activity' 
                optionB='Assessment' 
                tab={this.state.tab} 
                setTab={(value) => this.setState({ tab: value })} />
            {this.state.tab === 'Activity' && <Wrapper>
              <ActivityTab disableSeeAll={true} activity={this.state.activity} name={'You'} />
            </Wrapper>}
            {this.state.tab === 'Assessment' && <StudentProfileAssessmentSection
              percentDone={percentDone}
              retakeAssessment={this.retakeAssessment}
              user={user}
            />}
          </>
        );
      }
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    };

    if (retakeAssessment) {
      return <Redirect to="/assessment" />;
    }

    return (
      <>
        <PageWrapper>
          <ProfileHeader>
            <SettingsLink to="/settings">
              <FontAwesomeIcon icon={faCog} />
            </SettingsLink>
            <StudentPhoto user={user} />
            <ProfileName>
              {user.firstName} {user.lastName}
            </ProfileName>
            {renderStudentProfileDetails()}
          </ProfileHeader>
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.student.studentProfile,
});

export default connect(mapStateToProps, { getStudentProfile })(StudentProfile);
