import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import SubcategoryPanel from './SubcategoryPanel';
import SubcategoryItem from './student/StudentCategory/SubcategoryItem';
import { track, VIEW_CLUSTER } from '../utils/mixpanel';

const PageContainer = styled.div`
  width: 100%;
`;

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  padding: 25px;
`;

const PageLiner = styled.div`
  overflow-y: auto;

  &.noScroll {
    overflow-y: hidden;
  }
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 0.875rem;
  width: 100px;
  margin-top: -0.5rem;
  padding: 0.5rem 0;

  &:hover {
    color: #000000;
  }
`;

const ClusterHeader = styled.h3`
  //margin: 0.5rem 0 0;
  padding: 0.5rem 0 0;
  font-size: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const ClusterSubheader = styled.span`
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

class Cluster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backLink: '/clusters',
      cluster: {},
      loading: true,
      showSubcategoryPanel: false,
      skills: [],
      subcategory: {},
      subcategories: [],
    };

    this.pageLinerRef = React.createRef();

    this.toggleSubcategoryPanel = this.toggleSubcategoryPanel.bind(this);
  }

  componentDidMount() {
    const { clusterId } = this.props.match.params;
    const { backLink } = this.state;
    const backLinkParam = this.props?.location?.query?.backLink;

    if (backLinkParam && backLinkParam !== backLink) {
      this.setState({ backLink: backLinkParam });
    }

    API.get(`/opportunities/clusters/${clusterId}`)
      .then((res) => {
        res.data.cluster.subcategories.forEach((subcategory) => {
          res.data.opportunitiesCountBySubcategoriesOfCategory.forEach(
            (subcategoryData) => {
              if (subcategory.id === subcategoryData.subcategoryId) {
                subcategory.opportunitiesCount = subcategoryData.count
              }
            }
          );
        });

        this.setState({
          cluster: res.data,
          subcategories: res.data.cluster.subcategories,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        // TODO: handle error
        console.log(error);
      });
  }

  toggleSubcategoryPanel(subcategoryId) {
    const { showSubcategoryPanel, subcategories } = this.state;
    let subcategory = {};

    if (subcategoryId) {
      subcategory = subcategories.find((obj) => obj.id === subcategoryId);
    }

    if (!showSubcategoryPanel) {
      document.body.classList.add('noScroll');
      this.pageLinerRef.current.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
      this.pageLinerRef.current.classList.remove('noScroll');
    }

    this.setState({ showSubcategoryPanel: !showSubcategoryPanel, subcategory });
  }

  render() {
    const { user } = this.props;
    const {
      backLink,
      cluster,
      loading,
      showSubcategoryPanel,
      skills,
      subcategory,
      subcategories,
    } = this.state;

    if (loading) {
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    }

    track(VIEW_CLUSTER, { name: cluster.cluster.key } );

    const subcategoriesLength = subcategories.length;
    return (
      <PageContainer>
        <PageWrapper>
          <PageLiner ref={this.pageLinerRef}>
            <BackLink to={backLink}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </BackLink>
            <ClusterHeader>{cluster.name}</ClusterHeader>
            <ClusterSubheader>
              {subcategoriesLength}{' '}
              {subcategoriesLength === 1 ? 'Sub-category' : 'Sub-categories'}
            </ClusterSubheader>
            {subcategories.map((item) => {
              return (
                <SubcategoryItem
                  key={item.name}
                  cluster={cluster.cluster}
                  name={item.name}
                  description={item.description}
                  opportunitiesCount={item.opportunitiesCount}
                  subcategoryId={item.id}
                  isSaved={item.isSaved}
                  meta={`${
                    item.opportunitiesCount ? item.opportunitiesCount : 0
                  } ${
                    item.opportunitiesCount === 1
                      ? 'Opportunity'
                      : 'Opportunities'
                  }`}
                />
              );
            })}
          </PageLiner>
        </PageWrapper>
        <SubcategoryPanel
          showPanel={showSubcategoryPanel}
          skills={skills}
          subcategory={subcategory}
          toggleSubcategoryPanel={this.toggleSubcategoryPanel}
          user={user}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Cluster);
