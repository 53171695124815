import React from 'react';
import styled from 'styled-components';
import AssessmentStatus from '../shared/assessment-status';
import { INNER_PADDING, SPACING } from '../shared/default-styles';

const ActivityDescription = styled.p`
    margin-top: 10px;
    padding-left: ${SPACING};
`;

const AssessmentCompleteWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Detail = styled.p`
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    ${INNER_PADDING}
`;

const CategoryMatchWrapper = styled.div`
    background-color: #333333;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100px;
`;

const CategoryNameWrapper = styled.div`
    height: 60px;
    display: flex;
    align-items: flex-end;
`

const CategoryName = styled.p`
    color: white;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 0px;
`;

const CategoryOptions = styled.p`
    color: white;
    opacity: 0.8;
    font-size: 11px;
    margin-bottom: 0px;
`;

const CategoryMatches = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    ${INNER_PADDING}
`;

const SkillListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px ${SPACING};
`;

const Skill = styled.div`
    font-size: 12px;
    padding: 10px 10px;
    background-color: white;
    margin-right: 14px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-transform: capitalize;
`;

function CategoryMatch({ name, options }) {
    
    const roundedOptions = options > 100
        ? '100+'
        : options > 10 
        ? `${(Math.floor(options / 10)) * 10}+`
        : options;

    const optionsName = options > 1 ? 'Options' : 'Option';

    const optionsString = options === 0 ? 'No options available now' : `${roundedOptions} ${optionsName}`;

    return <CategoryMatchWrapper>
        <CategoryNameWrapper>
            <CategoryName>{name}</CategoryName>
        </CategoryNameWrapper>
        <CategoryOptions>{optionsString}</CategoryOptions>
    </CategoryMatchWrapper>
}

function SkillList({ skills }) {

    return <SkillListWrapper>
        {skills.slice(0,15).map(({ name }, i) => <Skill key={i}>{name}</Skill> )}
    </SkillListWrapper>

}

export default function AssessmentComplete({ matches, skills, name }) {
    
    const detail = "Based on the assessment answers here are the three categories that best matches skills and interests";


    return <AssessmentCompleteWrapper>
        <ActivityDescription>{name} completed the assessment</ActivityDescription>
        <AssessmentStatus style={{ padding: `0px ${SPACING}` }} percent={100} hideText />
        <Detail>{detail}</Detail>
        <CategoryMatches>
            {matches.map((m, i) => <CategoryMatch {...m} key={i} />)}
        </CategoryMatches>
        <ActivityDescription style={{marginTop: 30}}>Your skills</ActivityDescription>
        <SkillList skills={skills} />
    </AssessmentCompleteWrapper> 
}
