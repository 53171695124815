import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API } from '../settings';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import moment from 'moment';

const exportExcel = (byGrade) => {
  const filetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const wb = {
    Sheets: {} ,
    SheetNames: []
  };

  Object.keys(byGrade).forEach((key) => {
    const ws = XLSX.utils.json_to_sheet(byGrade[key]);
    wb.Sheets[key] = ws;
    wb.SheetNames.push(key);
  })

  const filename = 'launch-now_' + moment().format('DD-MM-YYYY') + '_report.xlsx';

  console.log(wb);
  console.log(filename);

  const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { file: filetype });
  FileSaver.saveAs(blob, filename, fileExtension);
}

function CopyInviteLink({user, ...props}) {

  const downloadReport = async () => {
    const response = await API.get('/reports/assessment?schoolId=' + schoolId);
    const { data } = response;
    const grades = {}
    data.forEach((d) => {
      const gradeSuffix = d.grade === 1 ? 'st' : d.grade === 2 ? 'nd' : d.grade === 3 ? 'rd' : 'th';
      const key = d.grade + gradeSuffix + ' grade';
      if (!grades[key]) grades[key] = [];
      grades[key].push(d);
      delete d.grade;
      d.percentDone = d.percentDone + '%';
    })
    
    exportExcel(grades)
  }

  const schoolId = user?.userTypeProfile?.schoolId;
  
  return <button {...props} onClick={downloadReport} className="ln-btn primary">
    <span>Download Report</span>
  </button>
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(
  withRouter(CopyInviteLink)
);
